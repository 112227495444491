import { TemplateComponent } from './template-component';
export class TemplateComponentContent {
    static parse(json) {
        const obj = new TemplateComponentContent();
        obj.id = json.template_component_content_id;
        obj.templateComponent = new TemplateComponent(json.template_component_id);
        obj.content = json.content;
        obj.locale = json.locale;
        obj.productId = json.product_id;
        obj.sampleContent = json.sample_content;
        obj.isDefaultInd = json.is_default_ind;
        return obj;
    }
    constructor(id, templateComponent, content, locale, productId, sampleContent, isDefaultInd, date, user_id, username) {
        this.id = id;
        this.templateComponent = templateComponent;
        this.content = content;
        this.locale = locale;
        this.productId = productId;
        this.sampleContent = sampleContent;
        this.isDefaultInd = isDefaultInd;
        this.date = date;
        this.user_id = user_id;
        this.username = username;
    }
    toJSON() {
        const obj = {};
        for (const prop in this) {
            if (prop === 'id') {
                obj['template_component_content_id'] = this[prop];
            }
            else if (prop === 'templateComponent') {
                obj['template_component_id'] = this[prop]['id'];
            }
            else if (prop === 'productId') {
                obj['product_id'] = this[prop];
            }
            else if (prop === 'sampleContent') {
                obj['sample_content'] = this[prop];
            }
            else if (prop === 'isDefaultInd') {
                obj['is_default_ind'] = this[prop];
            }
            else {
                obj[prop.toString()] = this[prop];
            }
        }
        return obj;
    }
    clone() {
        return new TemplateComponentContent(this.id, this.templateComponent, this.content, this.locale, this.productId, this.sampleContent, this.isDefaultInd);
    }
}
