import { CommonModule } from '@angular/common';
import { TextInputHighlightComponent } from './text-input-highlight.component';
import { TextInputHighlightContainerDirective } from './text-input-highlight-container.directive';
import { TextInputElementDirective } from './text-input-element.directive';
import * as i0 from "@angular/core";
export class TextInputHighlightModule {
    static { this.ɵfac = function TextInputHighlightModule_Factory(t) { return new (t || TextInputHighlightModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: TextInputHighlightModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule] }); }
}
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(TextInputHighlightModule, { declarations: [TextInputHighlightComponent,
        TextInputHighlightContainerDirective,
        TextInputElementDirective], imports: [CommonModule], exports: [TextInputHighlightComponent,
        TextInputHighlightContainerDirective,
        TextInputElementDirective] }); })();
